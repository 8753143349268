import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Container, classes } from "../styles/FooterStyles";
import { useNavigate, useLocation } from "react-router-dom";
import PrimaryButton from "./PrimaryButton";
import { Facebook, LinkedIn, WhatsApp, Instagram } from "@mui/icons-material";
import logo from "../assets/logo-1.png";

const Footer = ({ bookDemo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isInDashboard = location.pathname.startsWith("/admin/dashboard");

  return (
    <Container
      className={classes.root}
      style={{ display: isInDashboard ? "none" : "flex" }}
    >
      {/* <Typography className={classes.logo} children="LOGO" /> */}
      <img src={logo} />
      <div className={classes.innerContainer}>
        <div className={classes.contactContainer}>
          <Typography
            variant="h5"
            fontWeight="500"
            component="a"
            href="/contact-us"
            className={classes.link}
          >
            Looking to connect with us?
          </Typography>

          {location.pathname === "/" && (
            <PrimaryButton
              className={classes.primaryButton}
              text="Book a FREE Trial Class"
              style={{ width: 290 }}
              eventListener={() => bookDemo(navigate)}
            />
          )}
          <Typography
            variant="h5"
            fontWeight="500"
            className={classes.socialMedia}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton component="a" href="https://wa.me/919432115091">
                <WhatsApp className={classes.icon} color="success" />
              </IconButton>
              <IconButton
                component="a"
                href="https://www.facebook.com/eHobby.in"
              >
                <Facebook className={classes.icon} color="primary" />
              </IconButton>
              <IconButton
                component="a"
                href="https://www.linkedin.com/company/ehobby-in"
              >
                <LinkedIn className={classes.linkedin} />
              </IconButton>
              <IconButton
                component="a"
                href="https://www.instagram.com/ehobby.in"
              >
                <Instagram className={classes.instagram} />
              </IconButton>
            </div>
          </Typography>
        </div>
        <div className={classes.container}>
          <Typography variant="h5" fontWeight="500">
            Quick Links
          </Typography>
          <Box className={classes.linksGroup}>
            <Typography
              variant="h6"
              component="a"
              href="#why-ehobby"
              className={classes.link}
            >
              About
            </Typography>
            <Typography
              variant="h6"
              component="a"
              href="/"
              className={classes.link}
            >
              Courses
            </Typography>
            <Typography
              variant="h6"
              component="a"
              href="/book-demo"
              className={classes.link}
            >
              Book a Demo
            </Typography>
            <Typography
              variant="h6"
              component="a"
              href="/contact-us"
              className={classes.link}
            >
              Contact Us
            </Typography>
          </Box>
        </div>
        <div className={classes.container}>
          <Typography variant="h5" fontWeight="500">
            Connect with us
          </Typography>
          <Box className={classes.linksGroup}>
            <Typography
              variant="h6"
              component="a"
              href="tel: +91 94321-15091"
              className={classes.link}
            >
              +91 94321 15091
            </Typography>
            <Typography
              variant="h6"
              component="a"
              href="mailto: hello@ehobby.in"
              className={classes.link}
            >
              hello@ehobby.in
            </Typography>
          </Box>
        </div>
      </div>
      <Typography
        style={{
          fontSize: "13px",
          justifyContent: "center",
          marginTop: "25px",
        }}
      >
        <b>Copyright @ehobby.in {new Date().getFullYear()}</b>
      </Typography>
      <Typography style={{ fontSize: "13px", justifyContent: "center" }}>
        Intriped Innovation Pvt Ltd
      </Typography>
    </Container>
  );
};

export default Footer;
