import { Box, Button, ButtonGroup, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Tab, Tabs, Typography } from "@mui/material"
import moment from "moment"
import Input from "./Input"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { MuiTelInput } from "mui-tel-input"
import { Container, classes } from "styles/BookDemoStyles"
import { createDemo } from "context/dbActions/demo"
import { useEffect } from "react"
import axios from "axios"
import { useAuth } from "context/AuthContext"
import { fetchCourses } from "context/dbActions/course"
import { fetchSchedules, toggleSchedule } from "context/dbActions/schedule"

const DateButtons = ({ dates, formData, error, handleChange }) => {
	return (
		<div>
			<Typography className={classes.label}>Select Date</Typography>
			<ButtonGroup className={classes.buttonGroup} size="large">
				{dates.map(date => {
					const formatedDate = date.format("MM-DD-YYYY")
					const isSelected = formData.date === formatedDate
					const buttonColor = isSelected ? "white" : error ? "red" : "#1976d2"
					return (
						<Button
							variant={isSelected ? "contained" : "outlined"}
							className={classes.dateButton}
							style={{
								borderRadius: "inherit",
								borderColor: "inherit",
								color: buttonColor,
							}}
							name="date"
							key={date}
							value={formatedDate}
							onClick={handleChange}
						>
							{date.format("ddd")}
							<Divider className={classes.divider} />
							{date.format("Do MMM")}
						</Button>
					)
				})}
			</ButtonGroup>
		</div>
	)
}

const AgeButtons = ({ ageGroups, formData, error, handleChange }) => {
	return (
		<div>
			<Typography className={classes.label}>Student's Age</Typography>
			<ButtonGroup size="large" className={classes.buttonGroup}>
				{ageGroups.map(ageGroup => {
					const isSelected = formData.ageGroup === ageGroup
					const buttonColor = isSelected ? "white" : error ? "red" : "#1976d2"
					return (
						<Button
							className={classes.ageButton}
							variant={isSelected ? "contained" : "outlined"}
							style={{
								borderRadius: "inherit",
								borderColor: "inherit",
								color: buttonColor,
							}}
							key={ageGroup}
							name="ageGroup"
							value={ageGroup}
							onClick={handleChange}
						>
							{ageGroup}
						</Button>
					)
				})}
			</ButtonGroup>
		</div>
	)
}

const TimeSlotButtons = ({ formData, error, handleChange, schedules }) => {
	const day = moment(formData.date, "MM-DD-YYYY").format("dddd")
	const filteredByCourse = schedules.filter(schedule => schedule.course === formData.course)
	const slots = []
	filteredByCourse.map(schedule =>
		Object.entries(schedule).forEach(([day, slot]) => {
			if (typeof slot === "object") slots.push({ ...slot, day, mentor: schedule.id })
		})
	)
	const filteredByDay = slots.filter(slot => slot.day === day)
	const availableSlots = []
	filteredByDay.map(day =>
		Object.entries(day).forEach(([key, value]) => {
			if (!["mentor", "day"].includes(key)) {
				availableSlots.push({ time: key, available: value, mentor: day.mentor })
			}
		})
	)
	const filteredSlots = availableSlots.filter(slot => slot.available)
	const [value, setValue] = useState(0)

	const handleTimeZone = (_, newValue) => setValue(newValue)
	return (
		<div>
			<Typography className={classes.label}>Time Slot</Typography>
			<Box className={classes.tabBox}>
				<Tabs value={value} onChange={handleTimeZone}>
					<Tab label="EST" className={classes.tabText} />
					<Tab label="PST" className={classes.tabText} />
					<Tab label="CST" className={classes.tabText} />
				</Tabs>
			</Box>
			{filteredSlots.length > 0 ? (
				<div>
					{value === 0 && (
						<ButtonGroup className={classes.buttonGroup} size="large">
							{filteredSlots.map((slot, i) => {
								const formatedTime = moment(slot.time, "hh:mmA").format("hh:mm A")
								const isSelected = formData.timeSlot === `${slot.time} ${slot.mentor}` || (formData.timeSlot === formatedTime && formData.mentor === slot.mentor)
								const buttonColor = isSelected ? "white" : error ? "red" : "#1976d2"
								return (
									<Button
										className={classes.timeButton}
										variant={isSelected ? "contained" : "outlined"}
										style={{
											borderRadius: "inherit",
											borderColor: "inherit",
											color: buttonColor,
										}}
										name="timeSlot"
										key={i}
										value={`${slot.time} ${slot.mentor}`}
										onClick={handleChange}
									>
										{formatedTime}
									</Button>
								)
							})}
						</ButtonGroup>
					)}
					{value === 1 && (
						<ButtonGroup className={classes.buttonGroup} size="large" aria-label="large button group">
							{filteredSlots.map((slot, i) => {
								const formatedTime = moment(slot.time, "hh:mmA").format("hh:mm A")
								const isSelected = formData.timeSlot === `${slot.time} ${slot.mentor}` || (formData.timeSlot === formatedTime && formData.mentor === slot.mentor)
								const buttonColor = isSelected ? "white" : error ? "red" : "#1976d2"
								return (
									<Button
										className={classes.timeButton}
										variant={isSelected ? "contained" : "outlined"}
										style={{
											borderRadius: "inherit",
											borderColor: "inherit",
											color: buttonColor,
										}}
										name="timeSlot"
										key={i}
										value={`${slot.time} ${slot.mentor}`}
										onClick={handleChange}
									>
										{moment(slot.time, "hh:mmA").subtract(3, "h").format("hh:mm A")}
									</Button>
								)
							})}
						</ButtonGroup>
					)}
					{value === 2 && (
						<ButtonGroup className={classes.buttonGroup} size="large" aria-label="large button group">
							{filteredSlots.map((slot, i) => {
								const formatedTime = moment(slot.time, "hh:mmA").format("hh:mm A")
								const isSelected = formData.timeSlot === `${slot.time} ${slot.mentor}` || (formData.timeSlot === formatedTime && formData.mentor === slot.mentor)
								const buttonColor = isSelected ? "white" : error ? "red" : "#1976d2"
								return (
									<Button
										className={classes.timeButton}
										variant={isSelected ? "contained" : "outlined"}
										style={{
											borderRadius: "inherit",
											borderColor: "inherit",
											color: buttonColor,
										}}
										name="timeSlot"
										key={i}
										value={`${slot.time} ${slot.mentor}`}
										onClick={handleChange}
									>
										{moment(slot.time, "hh:mmA").subtract(1, "h").format("hh:mm A")}
									</Button>
								)
							})}
						</ButtonGroup>
					)}
				</div>
			) : (
				formData.date &&
				formData.course && (
					<Typography className={classes.label} textAlign="center">
						No Slots Available Today
					</Typography>
				)
			)}
		</div>
	)
}

const PhoneNumber = ({ phoneno }) => {
	return (
		<div>
			<Typography className={classes.label}>Your Registered Mobile Number</Typography>
			<MuiTelInput fullWidth value={phoneno} className={classes.label} disabled />
		</div>
	)
}

const BookDemo = ({ snackBar }) => {
	const { currentUser } = useAuth()
	const initialError = { date: false, timeSlot: false, ageGroup: false }
	const [error, setError] = useState(initialError)
	const [loading, setLoading] = useState(false)
	const [courses, setCourses] = useState([])
	const [schedules, setSchedules] = useState([])
	const ageGroups = ["Between 4 - 10", "Between 11 - 15", "Between 16 - 20", "21 & ABOVE"]
	const dates = [...Array(7).keys()].map(day => moment(moment()).add(day + 1, "days"))
	const user = { ...JSON.parse(localStorage.getItem("user")), phone: currentUser.phoneNumber }
	const initialState = { ...user, email: "", date: null, ageGroup: null, timeSlot: null, course: "", firstName: "", lastName: "" }
	const [formData, setFormData] = useState(initialState)
	const [checked, setChecked] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		if (!courses.length) {
			fetchData()
		}
	}, [courses.length])
	const fetchData = async () => {
		setCourses(await fetchCourses())
		setSchedules(await fetchSchedules())
	}

	const validate = () => {
		const errors = {
			date: !formData.date,
			ageGroup: !formData.ageGroup,
			timeSlot: !formData.timeSlot,
		}
		setError(errors)
		return !(errors.date || errors.ageGroup || errors.timeSlot)
	}
	const handleSubmit = async event => {
		event.preventDefault()
		if (!validate()) {
			return
		}
		setError(initialError)
		try {
			setLoading(true)
			const [time, mentor] = formData.timeSlot.split(" ") // Extracting TimeSlot and mentor
			const day = moment(formData.date, "MM-DD-YYYY").format("dddd") // Extracting Selected day
			const timeSlot = moment(time, "hh:mmA").format("hh:mm A")
			const finalData = { ...formData, timeSlot, mentor }
			setFormData(finalData)
			toggleSchedule(mentor, day, time) // Setting Mentor Schedule to occupied
			await saveInDatabase(finalData)
		} catch (error) {
			console.log(error.message)
			setLoading(false)
		}
	}
	const saveInDatabase = async (formData) => {
		try {
			const demoReference = await createDemo(formData) // Creating Demo and fetching demo Reference
			saveInForms(formData, demoReference)
			localStorage.setItem("demo", JSON.stringify(formData)) // Setting All data in localStorage
			setLoading(false)
			snackBar("info", `Meeting Link will be mailed to you for this demo`)
			navigate("/success-page")
		} catch (error) {
			console.log(error.message)
		}
	}
	const makeRequest = url => {
		let response
		try {
			response = axios.post(url, null, null)
		} catch (e) {
			response = e
		}
		console.log(response)
	}
	const saveInForms = (formData, demoReference) => {
		const id = process.env.REACT_APP_BOOKDEMO_FORM_ID // Form ID
		const data = {
			"entry.787882227": `${formData.firstName} ${formData.lastName}`, // Name
			"entry.1736043933": formData.phone, // Phone Number
			"entry.696611655": formData.email, //Email
			"entry.34187953": formData.course, // Course
			"entry.2077991617": `${formData.date} ${formData.timeSlot}`, // Demo Slot
			"entry.1160848818": "<To be manually Added>", // Meeting Link
			"entry.972685419": demoReference, // Demo Reference
			"entry.432364693": formData.mentor, // Mentor Assigned
			"entry.1692442787": "FALSE", // Completed
			"entry.1201765774": "<To be manually Added>", // Class Reference
		}
		const formUrl = "https://docs.google.com/forms/d/" + id + "/formResponse"
		const queryString = require("query-string")
		const q = queryString.stringifyUrl({
			url: formUrl,
			query: data,
		})
		makeRequest(q)
	}
	const handleChange = e => {
		// Resetting timeSlot if user changes date or course
		if (["course", "date"].includes(e.target.name)) {
			setFormData({ ...formData, [e.target.name]: e.target.value, timeSlot: null })
			return
		}
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}
	return (
		<Container className={classes.root}>
			<Paper elevation={6} component="main" className={classes.paper}>
				<Typography variant="h4" textAlign="center">
					BOOK A DEMO
				</Typography>
				<form className={classes.form} onSubmit={handleSubmit}>
					<Grid container spacing={2} style={{ justifyContent: "center" }}>
						<Grid className={classes.gridItem} item lg={6}>
							<Typography className={classes.label}>Student's Name</Typography>
							<Grid container spacing={1}>
								<Input name="firstName" label="First Name" type="text" onChange={handleChange} value={formData.firstName} autoFocus half />
								<Input name="lastName" label="Last Name" type="text" onChange={handleChange} value={formData.lastName} half />
							</Grid>
							<Typography className={classes.label}>Select a course</Typography>
							<FormControl fullWidth className={classes.label}>
								<InputLabel id="select-label">Course</InputLabel>
								<Select labelId="select-label" name="course" value={formData.course} label="Course" onChange={handleChange}>
									{courses.map(course => (
										<MenuItem key={course.id} value={course.name}>
											{course.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<Typography className={classes.label}>Email Address to schedule the session</Typography>
							<Input name="email" label="Email" type="email" onChange={handleChange} value={formData.email} />
							<div className={classes.telInput2}>
								<PhoneNumber phoneno={formData.phone} />
							</div>
							<DateButtons dates={dates} formData={formData} error={error.date} handleChange={handleChange} />
						</Grid>
						<Grid className={classes.gridItem} item lg={6}>
							<div className={classes.telInput1}>
								<PhoneNumber phoneno={formData.phone} />
							</div>
							<AgeButtons ageGroups={ageGroups} formData={formData} error={error.ageGroup} handleChange={handleChange} />
							<TimeSlotButtons formData={formData} error={error.timeSlot} handleChange={handleChange} schedules={schedules} />
						</Grid>
					</Grid>
					<FormControlLabel
						className={classes.checkBox}
						control={<Checkbox value={checked} onChange={() => setChecked(prev => !prev)} />}
						label="I agree that I am 18+ and booking the demo for me or someone related to me."
					/>
					<Button
						startIcon={loading && <CircularProgress size="2rem" />}
						type="submit"
						className={classes.submit}
						disabled={!checked || loading}
						fullWidth
						variant="contained"
						color="primary"
					>
						DONE
					</Button>
				</form>
			</Paper>
		</Container>
	)
}

export default BookDemo
