import { ArrowLeft } from "@mui/icons-material"
import { Button, Grid, Paper, Typography } from "@mui/material"
import { Container, classes } from "styles/BookDemoStyles"

const PaymentGateway = () => {
	const details = {
		"Account Holder Name":  process.env.REACT_APP_ACCOUNT_HOLDER_NAME,
		"Account Number": process.env.REACT_APP_ACCOUNT_NUMBER,
		"Bank Name":  process.env.REACT_APP_BANK_NAME,
		Branch:  process.env.REACT_APP_BRANCH,
		"IFSC CODE": process.env.REACT_APP_IFSC_CODE,
		"UPI ID":  process.env.REACT_APP_UPI_ID,
	}
	return (
		<Container className={classes.root}>
			<Paper elevation={6} component="main" className={classes.paper}>
				<Button startIcon={<ArrowLeft />} variant="contained" component="a" href="/">
					BACK
				</Button>
				<Typography variant="h4" textAlign="center" gutterBottom>
					PAYMENT DETAILS
				</Typography>
				<Grid container>
					<Grid item xs={12} lg={6}>
						{Object.entries(details).map(([label, data]) => (
							<Typography key={label} gutterBottom variant="h6">
								<strong>{`${label}: `}</strong> {data}
							</Typography>
						))}
					</Grid>
				</Grid>
				<Typography>
					After you have successfully made the payment in the above given bank details, we will allot you the slot you desired.
					<br />
					If you have any queries please <a href={`/contact-us`}> contact us</a>.
				</Typography>
			</Paper>
		</Container>
	)
}

export default PaymentGateway
