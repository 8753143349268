import { Typography, Box, Button } from "@mui/material";
import { Container, classes } from "styles/BannerStyles";
import arrow1 from "assets/arrow1.jpg";
import PrimaryButton from "../PrimaryButton";
import heroImage from "assets/hero1.png";
import { Link } from "react-router-dom";
import { useAuth } from "context/AuthContext";

const Banner = ({ bookDemo, openLogin }) => {
  const { currentUser, logOut } = useAuth();
  const handleLogout = async () => {
    try {
      await logOut();
    } catch {
      console.log("error");
    }
  };
  return (
    <Container className={classes.root}>
      <div className={classes.textContainer}>
        <Typography className={classes.textUpper}>
          LEARN FROM THE CERTIFIED & TRAINED
          <br className={classes.lineBreak} /> PROFESSIONALS
        </Typography>
        <br className={classes.lineBreak} />

        <Typography variant="h3" className={classes.text}>
          We offer a connection
          <br className={classes.lineBreak} /> to various art forms from India.
        </Typography>
        <Typography component="p" className={classes.textLower}>
          Experience the convenience of learning Indian art forms from
          <br className={classes.lineBreak} /> thecomfort of your home or any
          location at any time.
        </Typography>
        <Box className={classes.buttonGroup}>
          <PrimaryButton
            style={{
              backgroundColor: " #FFB343",
              fontSize: 15,
              boxShadow: "4px 2.5px 2.5px #aeaeae",
            }}
            eventListener={bookDemo}
            text="Book a FREE Trial Class"
          />
          <Button
            component={Link}
            to="/contact-us"
            className={classes.contactus}
          >
            CONTACT US
          </Button>
          {currentUser ? (
            <Button onClick={handleLogout} className={classes.login}>
              LOGOUT
            </Button>
          ) : (
            <Button onClick={() => openLogin(true)} className={classes.login}>
              LOGIN
            </Button>
          )}
        </Box>
      </div>
      <div className={classes.imageContainer}>
        <img src={heroImage} alt="hero background" width={600} />
      </div>
      {/* <img src={arrow1} className={classes.arrow1} alt="arrow1" /> */}
    </Container>
  );
};

export default Banner;
