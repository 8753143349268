// import { Grid, Typography } from "@mui/material";
// import { Container, classes } from "styles/ParentsVoice";
// import Yoga from "../../assets/Test.png";

// const ParentsVoice = () => {
//   return (
//     <Container className={classes.root}>
//       <Typography className={classes.text} gutterBottom>
//         Parent's Voice
//       </Typography>
//       <Grid container spacing={3} className={classes.gridContainer}>
//         <Grid item xs={10} sm={10} md={12} lg={3} className={classes.gridItem}>
//           <div
//             className={classes.item}
//             // style={{ backgroundImage: `url(${Yoga})` }}
//           >
//             <img src={Yoga} />
//             <div>
//               {" "}
//               {/* "I have thoroughly enjoyed my online yoga classes at ehobby. The
//               teachers are top-notch and the platform is user-friendly. Highly
//               recommend!" */}
//             </div>
//           </div>
//         </Grid>
//         <Grid item xs={10} sm={10} md={6} lg={3} className={classes.gridItem}>
//           <div className={classes.item} />
//         </Grid>
//         <Grid item xs={10} sm={10} md={6} lg={3} className={classes.gridItem}>
//           <div className={classes.item} />
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default ParentsVoice;

import React from "react";
import "../../App.css";

export default function Testimonials() {
  return (
    <div>
      <div className="cards-2">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12"
              style={{
                fontFamily: "Poppins",
                margin: "10px",
                padding: "0px",
                fontSize: "30px",
              }}
            >
              <h2>Our Hobby Family ❤️ </h2>
            </div>{" "}
          </div>{" "}
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-image">
                  ⭐⭐⭐⭐⭐
                  <hr className="testimonial-line" />
                </div>
                <div className="card-body">
                  <div
                    className="testimonial-text"
                    style={{ fontFamily: "Poppins", margin: "10px" }}
                  >
                    "I have been taking ehobby's online yoga classes and they
                    have greatly improved my practice. The skilled instructors
                    and convenient at-home format make it easy to fit yoga into
                    my busy schedule. Highly recommend!"
                  </div>
                  <div
                    className="testimonial-author"
                    style={{ fontFamily: "Poppins", margin: "10px" }}
                  >
                    Puja Nahata, Dallas, Texas{" "}
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-image">
                  ⭐⭐⭐⭐⭐
                  <hr className="testimonial-line" />
                </div>
                <div
                  className="card-body"
                  style={{ fontFamily: "poppins", margin: "10px" }}
                >
                  <div className="testimonial-text">
                    "My daughter has been taking Java coding classes with and
                    she is loving them! The lessons are engaging and the
                    instructor is knowledgeable and patient. I've seen a big
                    improvement in her coding skills and confidence in just a
                    few short weeks. We are extremely happy with ehobby's coding
                    classes and highly recommend them."
                  </div>
                  <div className="testimonial-author">
                    Dhruvi Rastogi, Illinios
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  ⭐⭐⭐⭐⭐
                  <hr className="testimonial-line" />
                </div>
                <div
                  className="card-body"
                  style={{ fontFamily: "poppins", margin: "10px" }}
                >
                  <div className="testimonial-text">
                    "My son has been taking piano lessons with ehobby and he
                    absolutely loves them! His instructor is patient and
                    encouraging, which has helped him to make rapid progress. We
                    are so glad we found ehobby and highly recommend their piano
                    classes."
                  </div>
                  <div className="testimonial-author">
                    Jyotsna Sharma, Atlanta
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
