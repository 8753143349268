import {
  Button,
  Card,
  ButtonBase,
  ButtonGroup,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Tab,
  Tabs,
  Typography,
  Grid,
} from "@mui/material";
import PrimaryButton from "components/PrimaryButton";
import { useState } from "react";
import { classes, Container, Box } from "styles/CoursesStyles";
import piano from "./../../assets/piano.jpg";
import courses from "../../data/courses.json";

const TabPanel = ({ value, title, index, bookDemo, dataArr, ...other }) => {
  return (
    <div hidden={value !== index} {...other}>
      {value === index && (
        <Box className={classes.root}>
          <Grid
            container
            spacing={{
              md: dataArr.length < 3 ? 28 : 5,
              xs: 5,
            }}
          >
            {dataArr.map((value, key) => (
              <Grid item sm={12} md={6} lg={4} key={key}>
                <ButtonBase>
                  <Card
                    className={classes.card}
                    elevation={5}
                    style={{ height: "500px" }}
                  >
                    <CardMedia
                      component="img"
                      image={value.image}
                      alt={title}
                      className={classes.media}
                    />
                    <CardContent className={classes.cardContent}>
                      <div
                        className={classes.title}
                        style={{ fontSize: "10px" }}
                      >
                        <Typography
                          style={{
                            fontSize: "22px",
                            textDecoration: "underline",
                            fontWeight: "500",
                            fontColor: "#000000",
                          }}
                          className={classes.titleText}
                          children={`${value.title}`}
                        />
                      </div>
                      <Typography
                        style={{
                          fontSize: "15px",
                          margin: "15px",
                        }}
                        className={classes.tagLine}
                        variant="body1"
                        textAlign="center"
                      >
                        {value.tagline}
                      </Typography>
                      <Divider
                        className={classes.divider}
                        style={{ marginBottom: "10px" }}
                      />
                      <CardActions
                        className={classes.actions}
                        style={{ marginTop: "5px" }}
                      >
                        <ButtonGroup variant="text">
                          <Button component="div" className={classes.buttons}>
                            Per Month:
                            <br />8 Classes | 12 Classes
                            <br /> Duration : 50 mins
                          </Button>
                        </ButtonGroup>
                        <br />
                        {/* 0                        <ButtonGroup>
                          <Button component="div" className={classes.buttons}>
                            Duration : 50 Mins
                          </Button>
                        </ButtonGroup> */}
                      </CardActions>
                      <PrimaryButton
                        component="div"
                        eventListener={bookDemo}
                        text="Book a FREE Demo"
                        style={{
                          marginTop: "20px",
                          backgroundColor: " #FFB343",
                          fontSize: 13,
                          boxShadow: "none",
                          height: 35,
                        }}
                      />
                    </CardContent>
                  </Card>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </div>
  );
};

const Courses = ({ bookDemo }) => {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => setValue(newValue);

  return (
    <Container className={classes.root}>
      <Typography className={classes.heading}>
        A Variety of Courses to Choose From
      </Typography>
      <br />
      <Box className={classes.tabBox} style={{ marginBottom: "40px" }}>
        <br />
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Music" className={classes.tabText} />
          <Tab label="Fitness" className={classes.tabText} />
          <Tab label="Coding" className={classes.tabText} />
          {/* <Tab label="More" className={classes.tabText} /> */}
        </Tabs>
      </Box>
      <TabPanel
        bookDemo={bookDemo}
        value={value}
        index={0}
        dataArr={courses.music}
      />
      <TabPanel
        bookDemo={bookDemo}
        value={value}
        index={1}
        dataArr={courses.fitness}
      />
      <TabPanel
        bookDemo={bookDemo}
        value={value}
        index={2}
        dataArr={courses.coding}
      />
    </Container>
  );
};

export default Courses;
