import {
	Button,
	ButtonGroup,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Typography,
	InputAdornment,
	Divider,
	IconButton,
	Box,
	Tabs,
	Tab,
} from "@mui/material"
import Input from "./Input"
import { useCallback, useEffect, useState } from "react"
import { Container, classes } from "styles/BookDemoStyles"
import { fetchUser } from "context/dbActions/user"
import { useAuth } from "context/AuthContext"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { fetchDemo } from "context/dbActions/demo"
import { fetchMentor } from "context/dbActions/mentor"
import { MuiTelInput } from "mui-tel-input"
import { fetchCourse } from "context/dbActions/course"
import { Clear } from "@mui/icons-material"
import { createClass } from "context/dbActions/class"
import axios from "axios"
import { fetchSchedules, toggleSchedule } from "context/dbActions/schedule"
import moment from "moment"

const useQuery = () => new URLSearchParams(useLocation().search)

const BookClass = () => {
	const initialState = {
		months: 1,
		classes: 1,
		slots: {},
		totalClasses: 1,
		amount: 0,
	}
	const [formData, setFormData] = useState(initialState)
	const query = useQuery()
	const demoReference = query.get("demo-ref")
	const { currentUser } = useAuth()
	const [demo, setDemo] = useState({})
	const [user, setUser] = useState({})
	const [mentor, setMentor] = useState({})
	const [loading, setLoading] = useState(false)
	const [creating, setCreating] = useState(false)
	const [checked, setChecked] = useState(false)
	const [course, setCourse] = useState({})
	const [day, setDay] = useState(null)
	const [slot, setSlot] = useState(null)
	const [error, setError] = useState(false)
	const [schedule, setSchedule] = useState({})
	useEffect(() => {
		const { months, classes } = formData
		const totalClasses = months * 4 * classes
		const amount = totalClasses * course.cost
		setFormData({ ...formData, totalClasses, amount })
	}, [formData.months, formData.classes, course.cost])

	const getData = useCallback(async () => {
		setLoading(true)
		const user = await fetchUser(currentUser.phoneNumber)
		setUser(user)
		if (user.demoReferences.includes(Number(demoReference))) {
			const demo = await fetchDemo(`${demoReference}`)
			const mentor = await fetchMentor(demo.mentor)
			const course = await fetchCourse(demo.course)
			const schedules = await fetchSchedules()
			const schedule = schedules.filter(schedule => schedule.id === demo.mentor)[0]
			setMentor(mentor)
			setDemo(demo)
			setCourse(course)
			setSchedule(schedule)
		}
		setLoading(false)
	}, [currentUser.phoneNumber, demoReference])
	const handleChange = e => {
		// Resetting timeSlot if user changes date or course
		if (["classes"].includes(e.target.name)) {
			setFormData({ ...formData, slots: {}, classes: e.target.value })
			return
		}
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}
	const navigate = useNavigate()
	const handleSubmit = async e => {
		e.preventDefault()
		if (formData.classes !== Object.keys(formData.slots).length) {
			return setError(true)
		}
		setError(false)
		setCreating(true)
		try {
			const finalData = {
				name: user.name,
				phone: user.phone,
				email: user.email,
				course: demo.course,
				startDate: "",
				endDate: "",
				meetingLink: "",
				mentor: demo.mentor,
				completed: false,
				slots: formData.slots,
				classes: formData.classes,
				months: formData.months,
				totalClasses: formData.totalClasses,
				totalAmount: formData.amount,
				amountReceived: false,
				enrolledFor: "",
				demoReference,
			}
			const {slots, mentor} = finalData
			const classReference = await createClass(finalData)
			saveInForms(finalData, classReference)
			Object.entries(slots).map(([day, time]) => toggleSchedule(mentor, day, time))
			navigate("/payment-gateway")
		} catch (error) {
			console.log(error.message)
		}
		setCreating(false)
	}
	const makeRequest = url => {
		try {
			axios.post(url, null, null)
		} catch (e) {
			console.log(e.message)
		}
	}

	const saveInForms = (formData, classReference) => {
		const { name, phone, email, course, mentor, slots, classes, months, totalClasses, totalAmount, amountReceived, enrolledFor, demoReference } = formData
		const id = process.env.REACT_APP_BOOKCLASS_FORM_ID
		const data = {
			"entry.677175639": name, // Full Name
			"entry.1883335824": phone, // Phone Number
			"entry.2095178224": email, // Email ID
			"entry.1609561333": course, //Course
			"entry.1277289519": "", // Start Date
			"entry.1862614088": "", //End Date
			"entry.1637661762": "", // Meeting Link
			"entry.1459005448": mentor, // Mentor Assigned
			"entry.1519922431": "FALSE", // Class Completed
			"entry.1801685395": JSON.stringify(slots), // Slots
			"entry.35436637": classes, // no. Classes booked per week
			"entry.2105053419": months, // no. Months booked
			"entry.420877223": totalClasses, // months * classes
			"entry.1817770978": totalAmount, // Total Amount
			"entry.1621538998": amountReceived, // Amount Recieved
			"entry.1147943106": enrolledFor, //Enrolled For
			"entry.1126955973": classReference, // Class Reference
			"entry.1377416356": demoReference, // Demo Reference
		}
		const formUrl = "https://docs.google.com/forms/d/" + id + "/formResponse"
		const queryString = require("query-string")
		const q = queryString.stringifyUrl({
			url: formUrl,
			query: data,
		})
		makeRequest(q)
	}
	useEffect(() => {
		getData()
	}, [getData])
	const ButtonProps = { day, setDay, slot, setSlot, formData, setFormData, schedule }
	const selectSlot = () => {
		const slots = {
			...formData.slots,
			[day]: slot,
		}
		setFormData({ ...formData, slots })
		setSlot(null)
		setDay(null)
	}

	const slotsSelected = Object.keys(formData.slots).length > formData.classes - 1
	const disableSelectSlot = !(day && slot)

	return (
		<Container className={classes.root}>
			{loading ? (
				<Paper elevation={6} component="main" className={classes.paper}>
					<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
						<CircularProgress size="5rem" />
					</div>
				</Paper>
			) : !demo.id ? (
				<Paper elevation={6} component="main" className={classes.paper}>
					<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
						<Typography variant="h6">Demo was not found</Typography>
					</div>
				</Paper>
			) : (
				<Paper elevation={6} component="main" className={classes.paper}>
					<Typography variant="h4" textAlign="center">
						BOOK CLASS
					</Typography>
					<form className={classes.form} onSubmit={handleSubmit}>
						<Grid container spacing={2} style={{ justifyContent: "center" }}>
							<Grid className={classes.gridItem} item lg={6} xs={12}>
								<Grid container spacing={1}>
									<Grid item lg={6} sm={12} xs={12}>
										<Typography className={classes.label}>Classes in a week</Typography>
										<FormControl fullWidth className={classes.label}>
											<InputLabel id="select-label">Classes</InputLabel>
											<Select labelId="select-label" name="classes" value={formData.classes} label="Classes" onChange={handleChange}>
												{[1, 2, 4].map(classes => (
													<MenuItem key={classes} value={classes}>
														{classes}
													</MenuItem>
												))}
												<MenuItem value="more" component={Link} to="/contact-us">
													If {">"} 4 contact us directly
												</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid item lg={6} sm={12} xs={12}>
										<Typography className={classes.label}>No. of Months</Typography>
										<FormControl fullWidth className={classes.label}>
											<InputLabel id="select-label">Months</InputLabel>
											<Select labelId="select-label" name="months" value={formData.months} label="Course" onChange={handleChange}>
												{[1, 2, 4].map(classes => (
													<MenuItem key={classes} value={classes}>
														{classes}
													</MenuItem>
												))}
												<MenuItem value="more" component={Link} to="/contact-us">
													If {">"} 4 contact us directly
												</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
								<Typography className={classes.label}>Select Slots</Typography>
								<Typography color="primary">
									* Slots can be changed after each Month Cycle based on Availability. To change a slot please <a href={`/contact-us`}> contact us</a>.
								</Typography>
								{!slotsSelected && (
									<div>
										<DayButtons {...ButtonProps} />
										{day && <TimeSlotButtons {...ButtonProps} />}
										<div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: 10 }}>
											<Button variant="contained" disabled={disableSelectSlot} onClick={selectSlot}>
												SELECT SLOT
											</Button>
										</div>
									</div>
								)}
							</Grid>
							<Grid className={classes.gridItem} item lg={6} xs={12}>
								<Typography className={classes.label}>Name</Typography>
								<Input value={demo.name} disabled />
								<Typography className={classes.label}>Email</Typography>
								<Input value={user.email} disabled />
								<Typography className={classes.label}>Phone</Typography>
								<MuiTelInput fullWidth value={user.phone} disabled />
								<Typography className={classes.label}>Course</Typography>
								<Input value={demo.course} disabled />
								<Typography className={classes.label}>Mentor</Typography>
								<Input value={mentor.name} disabled />
								<SelectedSlots {...ButtonProps} disabled />
								<Typography className={classes.label}>No. of classes in a week</Typography>
								<Input value={formData.classes} disabled />
								<Typography className={classes.label}>Total Classes</Typography>
								<Input value={formData.totalClasses} disabled />
								<Typography className={classes.label}>Total Amount to Pay</Typography>
								<Input
									disabled
									value={`${formData.amount}`}
									InputProps={{
										endAdornment: <InputAdornment position="end">${course.cost}/class</InputAdornment>,
									}}
								/>
							</Grid>
						</Grid>
						<FormControlLabel
							className={classes.checkBox}
							control={<Checkbox value={checked} onChange={() => setChecked(prev => !prev)} />}
							label="I agree that I am 18+ and booking the class for me or someone related to me."
						/>
						{error && <Typography color="error">Please Select Slots accordingly</Typography>}
						<Button
							startIcon={creating && <CircularProgress size="2rem" />}
							type="submit"
							className={classes.submit}
							disabled={!checked || creating}
							fullWidth
							variant="contained"
							color="primary"
						>
							PROCEED TO PAY
						</Button>
					</form>
				</Paper>
			)}
		</Container>
	)
}

const SelectedSlots = ({ formData, setFormData }) => {
	const deleteSlot = day => {
		const slots = {
			...formData.slots,
		}
		delete slots[day]
		setFormData({ ...formData, slots })
	}
	return (
		Object.keys(formData.slots).length > 0 && (
			<div>
				<Typography className={classes.label}>Slots Selected</Typography>
				<ButtonGroup
					className={classes.buttonGroup}
					size="large"
					style={{
						width: "auto",
					}}
				>
					{Object.entries(formData.slots).map(([day, time]) => {
						return (
							<div
								className={classes.dateButton}
								style={{
									borderRadius: 5,
									color: "#1976d2",
									border: "1px solid #1976d2",
									display: "flex",
									alignItems: "center",
									position: "relative",
									justifyContent: "center",
								}}
								key={day}
							>
								<IconButton onClick={() => deleteSlot(day)} style={{ position: "absolute", top: 0, right: 0 }}>
									<Clear color="error" size="1.2em" />
								</IconButton>
								<div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
									<Typography>{day}</Typography>
									<Divider className={classes.divider} />
									<Typography textAlign="center">
										{time}
										<br />
										(EST)
									</Typography>
								</div>
							</div>
						)
					})}
				</ButtonGroup>
			</div>
		)
	)
}

const DayButtons = ({ setDay, day: selectedDay }) => {
	const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
	return (
		<div>
			<Typography className={classes.label}>Days</Typography>
			<ButtonGroup className={classes.buttonGroup} size="large">
				{days.map(day => {
					const isSelected = selectedDay === day
					const buttonColor = isSelected ? "white" : "#1976d2"
					return (
						<Button
							variant={isSelected ? "contained" : "outlined"}
							className={classes.timeButton}
							style={{
								borderRadius: "inherit",
								borderColor: "inherit",
								color: buttonColor,
							}}
							key={day}
							onClick={() => setDay(day)}
						>
							{day}
						</Button>
					)
				})}
			</ButtonGroup>
		</div>
	)
}

const TimeSlotButtons = ({ slot: selectedSlot, setSlot, day, schedule }) => {
	useEffect(() => {
		setSlot(null)
	}, [day])
	const slots = []
	Object.entries(schedule[day]).forEach(([time, available]) => {
		if (available) slots.push(time)
	})
	const [value, setValue] = useState(0)

	const handleTimeZone = (_, newValue) => setValue(newValue)
	return slots.length > 0 ? (
		<div>
			<Typography className={classes.label}>Time Slots</Typography>
			<Box className={classes.tabBox}>
				<Tabs value={value} onChange={handleTimeZone}>
					<Tab label="EST" className={classes.tabText} />
					<Tab label="PST" className={classes.tabText} />
					<Tab label="CST" className={classes.tabText} />
				</Tabs>
			</Box>
			{value === 0 && (
				<ButtonGroup className={classes.buttonGroup} size="large" aria-label="large button group">
					{slots.map(slot => {
						const isSelected = slot === selectedSlot
						const buttonColor = isSelected ? "white" : "#1976d2"
						return (
							<Button
								className={classes.timeButton}
								variant={isSelected ? "contained" : "outlined"}
								style={{
									borderRadius: "inherit",
									borderColor: "inherit",
									color: buttonColor,
								}}
								key={slot}
								onClick={() => setSlot(slot)}
							>
								{moment(slot, "hh:mmA").format("hh:mm A")}
							</Button>
						)
					})}
				</ButtonGroup>
			)}
			{value === 1 && (
				<ButtonGroup className={classes.buttonGroup} size="large" aria-label="large button group">
					{slots.map(slot => {
						const isSelected = slot === selectedSlot
						const buttonColor = isSelected ? "white" : "#1976d2"
						return (
							<Button
								className={classes.timeButton}
								variant={isSelected ? "contained" : "outlined"}
								style={{
									borderRadius: "inherit",
									borderColor: "inherit",
									color: buttonColor,
								}}
								key={slot}
								onClick={() => setSlot(slot)}
							>
								{moment(slot, "hh:mmA").subtract(3, "h").format("hh:mm A")}
							</Button>
						)
					})}
				</ButtonGroup>
			)}
			{value === 2 && (
				<ButtonGroup className={classes.buttonGroup} size="large" aria-label="large button group">
					{slots.map(slot => {
						const isSelected = slot === selectedSlot
						const buttonColor = isSelected ? "white" : "#1976d2"
						return (
							<Button
								className={classes.timeButton}
								variant={isSelected ? "contained" : "outlined"}
								style={{
									borderRadius: "inherit",
									borderColor: "inherit",
									color: buttonColor,
								}}
								key={slot}
								onClick={() => setSlot(slot)}
							>
								{moment(slot, "hh:mmA").subtract(1, "h").format("hh:mm A")}
							</Button>
						)
					})}
				</ButtonGroup>
			)}
		</div>
	) : (
		day && <Typography textAlign="center">No slots available today</Typography>
	)
}

export default BookClass
