import { Grid, Typography } from "@mui/material";
import { classes, Container } from "styles/EnrollStyles";
import PrimaryButton from "../PrimaryButton";

const Enroll = ({ bookDemo }) => {
  const steps = [
    {
      title: "Choose a Course",
      detail:
        "To enroll in a course from ehobby, you need to first select the course that you are interested in. You can do this by browsing through the available courses on the website and selecting the one that meets your needs and interests.",
    },
    {
      title: "Book a FREE Demo",
      detail:
        "After selecting the course, you can book a FREE demo to get a feel for the course content and the teaching style of the instructor. This will give you a better idea of whether the course is suitable for you and whether you will be able to complete it successfully.",
    },
    {
      title: "Enroll for the Course",
      detail:
        "Once you have decided to enroll in the course, you can complete the enrollment process by paying the course fee. After the payment is processed, you will be given access to the course material and will be able to start learning right away.",
    },
  ];
  return (
    <Container className={classes.root}>
      <Typography className={classes.heading}>
        Step-by-Step Guide to Registering for a Course
      </Typography>
      <Grid className={classes.gridContainer} container spacing={3}>
        {steps.map((step, key) => (
          <Grid item xs={12} sm={6} md={8} lg={3} key={key}>
            <Typography variant="h4">{key + 1}.</Typography>
            <Typography className={classes.gridTitle} variant="h5">
              {step.title}
            </Typography>
            <Typography className={classes.gridDetail} variant="h6">
              {step.detail}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <PrimaryButton
        style={{
          backgroundColor: " #FFB343",
          fontSize: 15,
          boxShadow: "4px 2.5px 2.5px #aeaeae",
        }}
        eventListener={bookDemo}
        text="Book a FREE Trial Class"
      />
    </Container>
  );
};

export default Enroll;
