import { Avatar, Typography } from "@mui/material";
import { Container, classes } from "styles/ChooseUsStyles";
import { LibraryAddCheckOutlined, QuestionMark } from "@mui/icons-material";

const ChooseUs = () => {
  return (
    <Container className={classes.root} id="why-ehobby">
      <div className={classes.header}>
        <div style={{ display: "flex", gap: 10 }}>
          <Typography className={classes.text}>Why eHobby</Typography>
          <Avatar className={classes.question}>
            <QuestionMark className={classes.icon} />
          </Avatar>
        </div>
        <Typography variant="h5" className={classes.subHeader}>
          What sets us apart from other options?
        </Typography>
      </div>
      <div className={classes.body}>
        <div className={classes.point}>
          <LibraryAddCheckOutlined color="success" fontSize="large" />
          <Typography className={classes.pointText}>
            Learn From Certified Indian trained professionals.
          </Typography>
        </div>
        <div className={classes.point}>
          <LibraryAddCheckOutlined color="success" fontSize="large" />
          <Typography className={classes.pointText}>
            Join live, interactive online classes rather than watching
            pre-recorded lessons.
          </Typography>
        </div>
        <div className={classes.point}>
          <LibraryAddCheckOutlined color="success" fontSize="large" />
          <Typography className={classes.pointText}>
            Choose from a variety of time slots that cater to different time
            zones, including EST, PST, and CST.{" "}
          </Typography>
        </div>
        <div className={classes.point}>
          <LibraryAddCheckOutlined color="success" fontSize="large" />
          <Typography className={classes.pointText}>
            There is no age requirement for enrolling in our classes.
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default ChooseUs;
